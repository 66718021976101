import { useState, useCallback, useEffect } from "react";
import axios from "axios";

let logoutTimer;

export const useAuth = () => {
  const [userId, setUserId] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [userFirstName, setUserFirstName] = useState(false);
  const [userLastName, setUserLastName] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback(
    (uid, email, token, tokenExpires, firstName, lastName, isAdmin, resetPass) => {
      console.log("Token Expires at inside Login: ", tokenExpires);

      setUserId(uid);
      setUserEmail(email);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      setIsAdmin(isAdmin);
      setResetPass(resetPass);
      setToken(token);
      setTokenExpirationDate(tokenExpires);

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          email: email,
          token: token,
          tokenExpires: tokenExpires.toISOString(),
          firstName: firstName,
          lastName: lastName,
          isAdmin: isAdmin,
          reset_password: resetPass
        })
      );
    },
    []
  );

  const logout = useCallback(async () => {
    const logoutResponse = await axios.post("/api/users/logout");

    console.log(logoutResponse.data.messageResponse);

    setUserId(null);
    setUserEmail(null);
    setUserFirstName(null);
    setUserLastName(null);
    setIsAdmin(null);
    setResetPass(false);
    setToken(null);
    setTokenExpirationDate(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      console.log(
        `The time until the token expires is inside of useEffect in auth-hook: ${remainingTime} milliseconds ${
          remainingTime / 1000 / 60
        } minutes`
      );
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    const currentTime = new Date();
    console.log(
      "current time is inside 2nd useEffect in auth hook: ",
      currentTime
    );
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.tokenExpires) > currentTime
    ) {
      login(
        storedData.userId,
        storedData.email,
        storedData.token,
        new Date(storedData.tokenExpires),
        storedData.firstName,
        storedData.lastName,
        storedData.isAdmin,
        storedData.reset_password,
      );
    }
  }, [login]);

  return {
    token,
    login,
    logout,
    userId,
    userEmail,
    userFirstName,
    userLastName,
    isAdmin,
    resetPass,
    setResetPass
  };
};
