import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const ProjectModal = ({
  project,
  editProject,
  open,
  handleCancel,
  handleAddProject,
  handleUpdateProject,
}) => {
  const [currentProject, setCurrentProject] = useState({
    id: "",
    name: "",
    number: "",
    active: true,
    stratus: false,
    timecards: true,
  });

  useEffect(() => {
    if (project) {
      setCurrentProject(project);
      console.log("Here is the project inside the project modal: ", project);
    }
  }, [project, editProject]);

  const handleChange = (e) => {
    const itemChanged = e.target.name;

    let valueChanged;

    if (
      itemChanged === "stratus" ||
      itemChanged === "active" ||
      itemChanged === "timecards"
    ) {
      valueChanged = e.target.checked;
    } else {
      valueChanged = e.target.value;
    }

    const changeProject = { ...currentProject, [itemChanged]: valueChanged };
    setCurrentProject(changeProject);
  };

  const handleClose = () => {
    setCurrentProject({
      id: "",
      name: "",
      number: "",
      active: true,
      stratus: false,
      timecards: true,
    });

    handleCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    editProject
      ? handleUpdateProject(currentProject)
      : handleAddProject(currentProject);

    setCurrentProject({
      id: "",
      name: "",
      number: "",
      active: true,
      stratus: false,
      timecards: true,
    });
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          {editProject ? "Edit Project" : "New Project"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              required
              autoFocus
              fullWidth
              margin="normal"
              id="number"
              label="Project Number"
              name="number"
              autoComplete="off"
              type="text"
              value={currentProject.number}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              id="name"
              label="Project Name"
              name="name"
              autoComplete="off"
              type="text"
              value={currentProject.name}
              onChange={handleChange}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "secondary",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={currentProject.active}
                  />
                }
                label="Active"
                labelPlacement="start"
                id="active"
                name="active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={currentProject.timecards}
                  />
                }
                label="Timecards"
                labelPlacement="start"
                id="timecards"
                name="timecards"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={currentProject.stratus}
                  />
                }
                label="In Stratus"
                labelPlacement="start"
                id="stratus"
                name="stratus"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: 180 }}
              // onClick={handleSubmit}
            >
              {editProject ? "Update Project" : "Add Project"}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "25%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
