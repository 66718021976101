import { useState, useEffect} from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Changed the title of inventory header
export const InventoryHeader=()=> {
  
  const [value, setValue] = useState("orders");
  const [category, setCategory] = useState("vmi");
  
  const navigate = useNavigate();

  const location = useLocation().pathname;

  useEffect(()=>{
    console.log("Here is the location: ", location);
    if(location==='/inventory'){
      console.log("inventory location");
      if(value){
        setCategory("vmi");
      }
    } else if(location.includes("/inventory/vmi/orders/P1VMI-")&&!category){
      console.log("Inside orders a specific order");
      setValue("orders");
      setCategory("vmi")
    }
  },[location,value,category])

  const handleChange = (event,newValue) => {
    const group = event.target.name;

    if(group ==='list'){
      setValue(newValue);
      navigate(`/inventory${category?'/' + category:''}${newValue?'/' + newValue:''}`);
      console.log(`routed to: /inventory${category?'/' + category:''}${newValue?'/' + newValue:''}`);
    }else if(group==='category'){
      const newCategory= event.target.value;
      setCategory(newCategory);
      navigate(`/inventory${newCategory?'/' + newCategory:''}${value?'/' + value:''}`);
      console.log(`routed to: /inventory${newCategory?'/' + newCategory:''}${value?'/' + value:''}`);
    }
  };

  return (
    <div>
      <Box className="Inventory_Header" sx={{ paddingLeft:2, display:'flex', flexDirection:'row', height:10 }}>
        <FormControl variant="filled" size='small' sx={{ p:1, width: 150 }}>
          <InputLabel id="category">Category</InputLabel>
          <Select
            labelId="category"
            id="category"
            value={category}
            name="category"
            onChange={handleChange}
          >  
            <MenuItem value="vmi" >VMI</MenuItem>
            <MenuItem value="aquatherm">Aquatherm</MenuItem>
            <MenuItem value="p1parts">P1 Parts</MenuItem>
          </Select>
        </FormControl>
        {category?
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            pl:10
          }}
        >
          <Tab value="inventory" name="list" label="Inventory" />
          <Tab value="restocks" name="list" label="Restocks" />
          <Tab value="orders" name="list" label="Orders" />
          <Tab value="returns" name="list" label="Returns" />
        </Tabs>
        :
        ''}
      </Box>
      <Outlet />
    </div>
  );
}
