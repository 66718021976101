import {createSlice} from '@reduxjs/toolkit'

const initialAlertState ={
    open:false,
    title:'',
    message:''
};

const alertSlice = createSlice({
    name:'alert',
    initialState:initialAlertState,
    reducers:{
        showAlert(state,action){

            const {title, message}=action.payload;

            return {
                open:true,
                title:title,
                message:message
            }

        },
        closeAlert(state){

            return {
                open:false,
                title:'',
                message:''
            }
        }
    }
});

export const alertActions =alertSlice.actions;

export default alertSlice.reducer;