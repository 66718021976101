import { useContext, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import AlertDialog from "../utilities/AlertDialog";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Inject,
  ViewDirective,
  ViewsDirective,
  DragAndDrop,
  Print,
  ExcelExport,
  Resize,
  ResourceDirective,
  ResourcesDirective,
} from "@syncfusion/ej2-react-schedule";
import { DataManager, Query, UrlAdaptor } from "@syncfusion/ej2-data";
import { AuthContext } from "../context/auth-context";
import { L10n, addClass, remove, closest } from "@syncfusion/ej2-base";
import "./schedule.css";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:4000" : "";

L10n.load({
  "en-US": {
    schedule: {
      saveButton: "Save",
      cancelButton: "Cancel",
      deleteButton: "Remove",
      editEvent: "Change Package Schedule",
    },
  },
});

const Schedule = () => {
  const [dataManager, setDataManager] = useState(null);
  const [packagesToSchedule, setPackagesToSchedule] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const auth = useContext(AuthContext);
  const allowDragAndDrops = true;
  let scheduleObj = useRef(null);
  let treeObj = useRef(null);
  let ownerOneObj = useRef(null);
  let ownerTwoObj = useRef(null);
  let ownerThreeObj = useRef(null);
  const resourceData = [
    { Text: "Piping", Id: 1, Color: "#ea7a57" },
    { Text: "Plumbing", Id: 2, Color: "#df5286" },
    { Text: "Diakin", Id: 3, Color: "#865fcf" },
  ];
  // this one will group it by date
  // const group = { byDate: true, resources: ['Shop'] }
  // this one will show three calendars
  const group = { resources: ["Shop"] };

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    }),
    [auth.token]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const remoteData = new DataManager({
        url: `${baseUrl}/api/schedule/scheduledata`,
        crudUrl: `${baseUrl}/api/schedule/updateschedule`,
        adaptor: new UrlAdaptor(),
        headers: [{ Authorization: "Bearer " + auth.token }],
        crossDomain: true,
      });
      await remoteData.ready;
      setDataManager(remoteData);
    };

    const getPackages = async () => {
      const remotePackages = new DataManager({
        url: `${baseUrl}/api/schedule/packagestoschedule`,
        //crudUrl: "http://localhost:4000/api/schedule/updateschedule",
        adaptor: new UrlAdaptor(),
        headers: [{ Authorization: "Bearer " + auth.token }],
        crossDomain: true,
      });
      await remotePackages.ready;
      const data = await remotePackages.executeQuery(new Query());
      // The issue was I was not setting it to the result of the query
      setPackagesToSchedule(data.result);
      setActivePackages(data.result);
    };

    getPackages().then(() => {
      // Ensure the ScheduleComponent is refreshed after data is fully loaded
      if (treeObj.current) {
        console.log("Tree Object is current refreshing the schedule");
        getData().then(() => {
          console.log("Schedule data has been set");
          console.log(
            "Here is the remote data",
            scheduleObj.current.eventsData
          );
        });
      }
    });
  }, [auth.token]);

  // Function to handle the search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.value);
  };

  // this is the data added to search project and packages
  // Filtered data based on search query
  const filteredPackages = useMemo(() => {
    if (!searchQuery) {
      return activePackages;
    }
    return activePackages?.filter(
      (pkg) =>
        pkg.project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.package_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, activePackages]);

  // Update the TreeViewComponent data when filteredPackages changes
  useEffect(() => {
    if (treeObj.current) {
      treeObj.current.fields.dataSource = filteredPackages;
    }
  }, [filteredPackages]);

  const formatDate = (dateString) => {
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // this sets what the packages display on the calendar
  const eventTemplate = (props) => {
    return `${props.project_name}: ${props.package_name} ${
      props.comments ? props.comments : ""
    }`;
  };

  // this shows what displays when someone hovers over a calendar item
  // const tooltemp = (props) => {
  //   return `${props.project_name}:       ${props.package_name} ${
  //     props.required_dt ? "Ship By: " + props.required_dt : ""
  //   } ${"Status: " + props.status_name} ${
  //     props.comments ? "Notes: " + props.comments : ""
  //   }`;
  // };

  const toolTemplate =
    '<div class="tooltip-wrap">' +
    '<div class="content-area"><div class="project_name">${project_name}</></div>' +
    '<div class="content-area"><div class="name">${package_name}</></div>' +
    '<div class="content-area"><div class="shipby">Ship By: ${required_dt}</></div>' +
    '<div class="content-area"><div class="status">Status: ${status_name}</div></div>' +
    '<div class="content-area"><div class="comments">Notes: ${comments}</div>' +
    "</div></div>";

  // this is the settings for the calendar
  const eventSettings = useMemo(
    () => ({
      dataSource: dataManager,
      template: eventTemplate,
      allowResizing: true,
      allowDragAndDrop: true,
      editFollowingEvents: true,
      enableTooltip: true,
      tooltipTemplate: toolTemplate,
      fields: {
        id: "id",
        subject: { name: "project_name", title: "Project" },
        location: { name: "package_name", title: "Package" },
        startTime: { name: "starttime", title: "Fab Start" },
        endTime: { name: "endtime", title: "Fab End" },
        isAllDay: { name: "isallday" },
        description: { name: "comments", title: "Notes" }, // Custom field mapped here
      },
    }),
    [dataManager]
  );

  const fields = {
    dataSource: activePackages,
    id: "id",
    text: "package_name",
  };

  // This sets what is seen for each package in the Tree View
  const treeTemplate = (props) => {
    const isSelected = datePickerVisible && props.id === selectedNode.id;

    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitlist">{props.project_name}</div>
          <div id="waitcategory" title={props.package_name}>
            {props.package_name}
          </div>
          <div onDoubleClick={() => onNodeClick(props)} id="waitrequired">
            Required: {props.required_dt && formatDate(props.required_dt)}
          </div>
          {isSelected && (
            <div id="reqcalendar">
              <CalendarComponent
                change={onDateChange}
                value={props.required_dt ? new Date(props.required_dt) : null}
              ></CalendarComponent>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onItemSelecting = (args) => {
    args.cancel = true;
  };

  const onTreeDrag = (event) => {
    // this is pretty much checking to see if the scheduleObj is in an adaptive state like on a smaller screen or mobile device to add and remove the e-device-hover class
    if (scheduleObj.current.isAdaptive) {
      let classElement =
        scheduleObj.current.element.querySelector(".e-device-hover");
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover");
      }
    }
  };

  // This starts when you stop dragging and adds the item to the schedule
  const onTreeDragStop = (event) => {
    // so this is seeing if I dropped it over the tree view and then below does not run the code if I am over the tree view.
    let treeElement = closest(event.target, ".e-treeview");
    // this will basically remove the e-device-hover class if it is applied which appears to only happen on smaller adaptive screens basically taking away the hovering effect
    let classElement =
      scheduleObj.current.element.querySelector(".e-device-hover");
    if (classElement) {
      classElement.classList.remove("e-device-hover");
    }

    if (!treeElement) {
      // if it shows that it was not dropped over the tree than it will look for the schedule body by looking for the e-content-wrap class on this div and return it
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");
      // if it finds the schedule then it will go on to this
      if (scheduleElement) {
        let treeviewData = treeObj.current.fields.dataSource;
        // The treeview data is all the objects in the tree
        // this then filters the treelist for the item I just dragged by comparing the ids
        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.filter(
            (item) => item.id === event.draggedNodeData.id
          );
          // this the package I just dragged onto the schedule
          let cellData = scheduleObj.current.getCellDetails(event.target);
          // This captures the new event data from what I dragged
          let eventData = {
            id: filteredData[0].id,
            project_name: filteredData[0].project_name,
            starttime: cellData.startTime,
            endtime: cellData.endTime,
            isallday: true,
            package_name: filteredData[0].package_name,
            FollowingID: filteredData[0].id,
          };

          // This adds the event on the calendar at the dropped node
          scheduleObj.current.addEvent(eventData);
        }
      }
    }
    document.body.classList.remove("e-disble-not-allowed");
  };

  // this will filter down the list of the packages on the tree view and remove the package just scheduled it will check if an event has been added and if so remove that event from the
  //list of items to be scheduled
  const onActionBegin = (event) => {
    // console.log("onActionBegin called with: ", event);

    // the even create type filters out changed events so it only removes items just created
    if (event.requestType === "eventCreate") {
      const addedItemId = event.addedRecords[0].id;
      let treeViewData = treeObj.current.fields.dataSource;
      console.log(
        "Adding the following item id to the schedule: ",
        addedItemId
      );
      console.log(
        "Inside on Action Begin and the treeview data is: ",
        treeViewData
      );

      const filteredPackages = treeViewData.filter(
        (pack) => pack.id !== addedItemId
      );

      console.log("here is the filtered packages: ", filteredPackages);

      treeObj.current.fields.dataSource = filteredPackages;

      const newPackagesToSchedule = packagesToSchedule.filter(
        (pack) => pack.id !== addedItemId
      );

      setPackagesToSchedule([...newPackagesToSchedule]);
      // console.log("The filtered packages are: ", filteredPackages);
      // let elements = document.querySelectorAll(
      //   ".e-drag-item.treeview-external-drag"
      // );
      // for (let i = 0; i < elements.length; i++) {
      //   remove(elements[i]);
      // }
    }
  };

  const onTreeDragStart = () => {
    document.body.classList.add("e-disble-not-allowed");
  };

  const onNodeClick = (args) => {
    const nodeData = args;
    setSelectedNode(nodeData);
    setDatePickerVisible(true);
  };

  const onDateChange = async (e) => {
    const reqDate = e.value;
    const packageId = selectedNode.id;

    if (selectedNode) {
      let treeViewData = treeObj.current.fields.dataSource;

      const updatedPackages = treeViewData.map((pkg) => {
        if (pkg.id === packageId) {
          return { ...pkg, required_dt: reqDate };
        }
        return pkg;
      });
      treeObj.current.fields.dataSource = updatedPackages;
      setSelectedNode(null);
      setDatePickerVisible(false);

      try {
        const data = {
          id: packageId,
          date: reqDate,
        };
        const dateResponse = await axios.post(
          "/api/schedule/updatereqdate",
          data,
          requestConfig
        );

        const changedItem = dateResponse.data.changed;

        console.log("Here is the response from the server:", changedItem);
      } catch (err) {
        const errMessage = err.response.data
          ? err.response.data
          : "The server encountered the following error while updating the required date: " +
            err.message;
        console.log("There was an error updating the required date: ", err);

        dispatch(
          alertActions.showAlert({
            title: "Error Updating the Required Date",
            message: errMessage,
          })
        );
      }
    }
  };

  // this is triggered when an event is added or edited and automatically closes adding an event so they can only be added from the packages to schedule
  const closeAddEvent = (args) => {
    console.log("Popup opened with this: ", args);
    if (!args.data.id) {
      console.log("Canceling add event since no event selected");
      args.cancel = true;
    }
  };

  const onEventRendered = (args) => {
    // console.log(args);
    let statusColor = args.data.status_color;

    // Define the corresponding text colors for the background colors
    const textColors = {
      "#ffff00": "#000000", // Black text for light yellow background
      "#FC00FF": "#FFFFFF", // White text for magenta background
      "#bf3542": "#FFFFFF", // White text for dark red background
      "#11C9DD": "#000000", // Black text for light blue background
      "#02011C": "#FFFFFF", // White text for dark blue background
      "#FF4100": "#FFFFFF", // White text for orange background
      "#FF7F00": "#000000", // Black text for orange-yellow background
      "#970DF1": "#FFFFFF", // White text for purple background
      "#F902C1": "#FFFFFF", // White text for pink background
      "#0BFF1D": "#000000", // Black text for light green background
      "#FF0A00": "#FFFFFF", // White text for red background
      "#F1B8FE": "#000000", // Black text for light pink background
      "#999999": "#000000", // Black text for gray background
      "#C6AE12": "#000000", // Black text for mustard yellow background
      "#D7F986": "#000000", // Black text for pale green background
      "#f20775": "#FFFFFF", // White text for hot pink background
    };

    if (statusColor) {
      args.element.style.backgroundColor = statusColor;
    }

    // Set the text color based on the background color
    let textColor = textColors[statusColor] || "#FFFFFF"; // Default to white if not found
    args.element.style.color = textColor;
  };

  const onActionFailure = async (args) => {
    console.log("Calling Action Failure: ", args);

    const showAlert = async (message) => {
      dispatch(
        alertActions.showAlert({
          title: "There was an error updating the schedule",
          message,
        })
      );
    };

    try {
      const error = args.error?.[0]?.error;

      if (error) {
        const text = await error.text();
        console.log("Here is the error object: ", error);
        console.log(text);

        showAlert(
          `The following error happened while updating the schedule: ${text}. The schedule is being refreshed with the current data. Please enter your information again.`
        );

        scheduleObj.current.refresh();

        let checkBoxes = [
          ownerOneObj.current,
          ownerTwoObj.current,
          ownerThreeObj.current,
        ];

        for (const checkBoxObj of checkBoxes) {
          if (!checkBoxObj.checked) {
            console.log(checkBoxObj);

            checkBoxObj.checked = true;
          }
        }
      } else {
        showAlert(
          "An unknown error occurred. The schedule is being refreshed with the current data. Please enter your information again."
        );
      }
    } catch (err) {
      console.log(err);
      showAlert(
        "The schedule is being refreshed with the current data. Please enter your information again."
      );
    }
  };

  const onChange = async (args) => {
    setSearchQuery("");
    const value = args.event.currentTarget
      .querySelector("input")
      .getAttribute("value");

    const valueInt = parseInt(value, 10);

    const resourceSelected = resourceData.filter(
      (item) => item.Id === valueInt
    );

    let checkBoxes = [
      ownerOneObj.current,
      ownerTwoObj.current,
      ownerThreeObj.current,
    ];
    // const filteredPackages = [...packagesToSchedule];
    // // console.log("Here are the filtered packages before: ", filteredPackages);

    // checkBoxes.forEach((checkBoxObj) => {
    //   if (!checkBoxObj.checked) {
    //     const checkBoxValue = parseInt(checkBoxObj.value, 10);

    //     // console.log(
    //     //   "Here is the checkbox value currently processing: ",
    //     //   checkBoxValue
    //     // );

    //     for (let i = filteredPackages.length - 1; i >= 0; i--) {
    //       if (filteredPackages[i].shop_id === checkBoxValue) {
    //         filteredPackages.splice(i, 1);
    //       }
    //     }

    //     // console.log(
    //     //   "Here is the filtered packages in the mutation: ",
    //     //   filteredPackages
    //     // );
    //   }
    // });

    // // console.log("Here is the filtered packages after: ", filteredPackages);

    // Async function to filter packages
    const filterPackages = async () => {
      const filteredPackages = [...packagesToSchedule];

      for (const checkBoxObj of checkBoxes) {
        if (!checkBoxObj.checked) {
          const checkBoxValue = parseInt(checkBoxObj.value, 10);

          // Remove packages with matching shop_id from filteredPackages
          for (let i = filteredPackages.length - 1; i >= 0; i--) {
            if (filteredPackages[i].shop_id === checkBoxValue) {
              filteredPackages.splice(i, 1);
            }
          }
        }
      }

      return filteredPackages;
    };

    // Wait for filtering to complete before assigning to treeObj
    const filteredPackages = await filterPackages();

    treeObj.current.fields.dataSource = filteredPackages;
    setActivePackages(filteredPackages);

    if (args.checked) {
      scheduleObj.current.addResource(resourceSelected[0], "Shop", valueInt);
    } else {
      scheduleObj.current.removeResource(valueInt, "Shop");
    }
  };

  const onExportClick = () => {
    let fieldHeaders = [
      { name: "shop_name", text: "Shop" },
      { name: "project_name", text: "Project" },
      { name: "package_name", text: "Package" },
      { name: "starttime", text: "Fab Start" },
      { name: "endtime", text: "Fab Complete" },
      { name: "required_dt", text: "Ship By" },
      { name: "status_name", text: "Status" },
      { name: "comments", text: "Comments" },
    ];

    const today = new Date();
    const formattedDate = `${
      today.getMonth() + 1
    }/${today.getDate()}/${today.getFullYear()}`;
    let scheduleTitle = `${formattedDate} P1 Fabrication Schedule`;

    let exportValues = {
      fields: [
        "shop_name",
        "project_name",
        "package_name",
        "starttime",
        "endtime",
        "required_dt",
        "status_name",
        "comments",
      ],
      fieldsInfo: fieldHeaders,
      fileName: scheduleTitle,
    };
    scheduleObj.current.exportToExcel(exportValues);
  };

  const onPrintClick = () => {
    scheduleObj.current.print();
  };

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        <div className="control-wrapper drag-sample-wrapper">
          <div className="schedule-container">
            <div className="schedule-title-container">
              <h1 className="title-text">Production Schedule</h1>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CheckBoxComponent
                        ref={ownerOneObj}
                        value="1"
                        id="Piping"
                        checked={true}
                        label="Piping"
                        change={onChange}
                      />
                    </td>

                    <td>
                      <CheckBoxComponent
                        ref={ownerTwoObj}
                        value="2"
                        id="Plumbing"
                        checked={true}
                        label="Plumbing"
                        change={onChange}
                      />
                    </td>

                    <td>
                      <CheckBoxComponent
                        ref={ownerThreeObj}
                        value="3"
                        id="Diakin"
                        checked={true}
                        label="Diakin"
                        change={onChange}
                      />
                    </td>
                    <td style={{ paddingLeft: "10px", textAlign: "center" }}>
                      <div>
                        <ButtonComponent
                          iconCss="e-icons e-download"
                          cssClass="e-print-btn"
                          onClick={onExportClick}
                        >
                          Excel
                        </ButtonComponent>
                      </div>
                    </td>
                    <td style={{ paddingLeft: "10px", textAlign: "center" }}>
                      <div>
                        <ButtonComponent
                          iconCss="e-icons e-print"
                          cssClass="e-print-btn"
                          onClick={onPrintClick}
                        >
                          Print
                        </ButtonComponent>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <AlertDialog />
            <ScheduleComponent
              width="100%"
              height="80vh"
              eventSettings={eventSettings}
              cssClass="schedule-drag-drop"
              ref={scheduleObj}
              currentView="Month"
              actionBegin={onActionBegin}
              popupOpen={closeAddEvent}
              // Uncommenting eventRendered will color them according to status.  commenting will color them accoriding to resource
              eventRendered={onEventRendered}
              rowAutoHeight={true}
              actionFailure={onActionFailure}
              group={group}
            >
              <ResourcesDirective>
                <ResourceDirective
                  field="shop_id"
                  title="Shop"
                  name="Shop"
                  dataSource={resourceData}
                  textField="Text"
                  idField="Id"
                  colorField="Color"
                />
              </ResourcesDirective>
              <ViewsDirective>
                <ViewDirective option="Day" timeScale={{ enable: false }} />
                <ViewDirective option="Week" timeScale={{ enable: false }} />
                <ViewDirective option="Month" />
              </ViewsDirective>
              <Inject
                services={[
                  Day,
                  Week,
                  Month,
                  DragAndDrop,
                  Print,
                  Resize,
                  ExcelExport,
                ]}
              />
            </ScheduleComponent>
          </div>
          <div>
            <div className="unscheduled-title-container">
              <div className="unscheduled-title-box">
                <h1 className="title-text">Packages to Schedule</h1>
              </div>
              {/* Search input for filtering packages */}
              {/* <input
                type="text"
                placeholder="Search by Project or Package"
                value={searchQuery}
                onChange={handleSearchChange}
                className="treeview-search-input"
              /> */}
              <TextBoxComponent
                placeholder="Search for Project or Package"
                cssClass="e-outline custom-blue-focus"
                floatLabelType="Auto"
                value={searchQuery}
                input={handleSearchChange}
                showClearButton={true}
              />
            </div>
            <div className="treeview-container">
              <TreeViewComponent
                ref={treeObj}
                cssClass="treeview-external-drag"
                dragArea=".drag-sample-wrapper"
                fields={fields}
                nodeTemplate={treeTemplate}
                nodeSelecting={onItemSelecting}
                nodeDragging={onTreeDrag}
                nodeDragStart={onTreeDragStart}
                nodeDragStop={onTreeDragStop}
                allowDragAndDrop={allowDragAndDrops}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
