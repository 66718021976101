import {useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";
import { AuthContext } from "../../context/auth-context";


export const ChangePassword = () => {

  const auth = useContext(AuthContext);

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [disablePassCheck, setDisablePassCheck]= useState(true)
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [passMatch, setPassMatch]= useState(true)

  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const itemChanged = e.target.name;
    const valueChanged =e.target.value;
    
    if (itemChanged === "current_password"){
        setCurrentPassword(valueChanged)
    } else if (itemChanged === "new_password"){
        setNewPassword(valueChanged);
        console.log(valueChanged.length);
        if(valueChanged.length>7){
          setDisablePassCheck(false)
          if(checkPassword!==''){
              if(valueChanged!==checkPassword){
                  setPassMatch(false);
                  setDisableSubmit(true)
              } else {
                  setPassMatch(true);
                  if(currentPassword!==''){
                      setDisableSubmit(false)
                  }
              }
          }
        } else {
          setDisablePassCheck(true)
        }
    } else if (itemChanged ==="check_password"){
        setCheckPassword(valueChanged);
        if(valueChanged!==newPassword){
            setPassMatch(false)
            setDisableSubmit(true)
        } else {
            setPassMatch(true);
            if(currentPassword!==''){
                setDisableSubmit(false)
            }
        }
    }

  };

  const handleClose = () => {
    setCurrentPassword('');
    setNewPassword('');
    setCheckPassword('');
    setDisableSubmit(true);
    setDisablePassCheck(true)
    if(auth.resetPass){
      auth.logout();
    }
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data ={ 
        current_password: currentPassword,
        new_password: newPassword,
        check_password: checkPassword
     }

    try{
        const changePassword = await axios.put(
            "/api/users/updatepassword",
            data,
            requestConfig
          );

          console.log(changePassword);

          alert(changePassword.data.updateResponse)
          auth.setResetPassword(false)
          setCurrentPassword('');
          setNewPassword('');
          setCheckPassword('')
          navigate("/");

    } catch(err){
        console.log("There was an error changing the password: ", err);
        alert(err.response.data.message);
        setCurrentPassword('');
        setNewPassword('');
        setCheckPassword('');
        setDisableSubmit(true);
        setDisablePassCheck(true);
        if(auth.resetPass){
          auth.logout();
        }
    }
    
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            autoFocus
            fullWidth
            margin="normal"
            id="current_password"
            label="Current Password"
            name="current_password"
            autoComplete="current_password"
            type="password"
            value={currentPassword}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="new_password"
            label="New Password"
            name="new_password"
            autoComplete="off"
            type="password"
            value={newPassword}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="check_password"
            label="Verify Password"
            name="check_password"
            autoComplete="off"
            type="password"
            value={checkPassword}
            disabled={disablePassCheck}
            onChange={handleChange}
          />
            <DialogContentText sx={{color:'red', display:passMatch?'none':'initial'}}>***Passwords do not match***</DialogContentText>
            <DialogContentText sx={{color:'red', display:newPassword.length>0?(disablePassCheck?'initial':'none'):'none'}}>***Passwords must be a minnimum of 8 characters***</DialogContentText>
          <DialogActions>
            <Button type="submit" variant="contained" disabled={disableSubmit} sx={{ width: "40%" }}>
              Update Password
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "25%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
