import { useState, useEffect, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import AlertDialog from "../utilities/AlertDialog";
import { AddItemModal } from "./AddItemModal";
import { AuthContext } from "../context/auth-context";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const OrderScanModal = ({
  scannerOpen,
  scannerCancel,
  handleAddItems,
  vendor_id
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [currentQty, setCurrentQty] = useState(1);
  const [scannedItems, setScannedItems] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [currentDesc, setCurrentDesc] = useState("");
  const [itemOptions, setItemOptions] = useState([]);
  const [itemSelected, setItemSelected] = useState("");
  const [scanningLabel, setScanningLabel] = useState(false);
  const [numItems, setNumItems] = useState(0);
  const [page, setPage] = useState(0);
  const [showAddInv, setShowAddInv] = useState(false);
  const [vendorId,setVendorId] = useState(vendor_id);

  const auth = useContext(AuthContext);

  const dispatch = useDispatch();

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
      params:{vendor_id:vendor_id}
    }),
    [auth.token, vendor_id]
  );

  const columns = [
    { field: "row", headerName: "#", flex: 0.5 },
    { field: "part_no", headerName: "SKU", flex: 1 },
    { field: "qty", headerName: "Qty", flex: 1 },
    { field: "size", headerName: "Size", flex: 1 },
    {
      field: "family",
      headerName: "Description",
      flex: 3,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      headerName: "",
      cellClassName: "actions",

      getActions: (item) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="action" />}
          name="delete"
          label="Delete"
          onClick={() => {
            handleDeleteItem(item);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  // This will get the inventory when the page is loaded.
  useEffect(() => {
    const getInventory = async () => {
      try {
        const responseData = await axios.get(
          "/api/purchasing/vmiinventory",
          requestConfig
        );

        const dbInventory = responseData.data.vmi_inventory;

        console.log("Here is the inventory:", dbInventory);

        setInventory(dbInventory);
        setVendorId(vendor_id);

        const descr = dbInventory.map((item) => {
          return item.description;
        });

        const uniqueDesc = descr.filter(
          (descr, index, array) => array.indexOf(descr) === index
        );

        setDescriptions(uniqueDesc);
      } catch (err) {
        console.log("There was an error getting the inventory: ", err);
        dispatch(
          alertActions.showAlert({
            title: "Error Retrieving Inventory",
            message:
              "There was an error getting the inventory:  " + err.message,
          })
        );
      }
    };

    if (scannerOpen) {
      getInventory();
     
      setShowModal(true);

    }
  }, [scannerOpen, requestConfig]);

  // this updates the item as it is typed
  const handleChange = (e) => {
    const nameChanged = e.target.name;
    const valueChanged = e.target.value;

    if (nameChanged === "desc") {
      setCurrentDesc(valueChanged);
      const filteredOptions = inventory.filter(
        (item) => item.description === valueChanged
      );
      setItemOptions(filteredOptions);
    } else if (nameChanged === "size") {
      setItemSelected(valueChanged);
    } else if (nameChanged === "qty") {
      setCurrentQty(valueChanged);
    } else {
      setCurrentItem(valueChanged);
    }
  };

  // This deletes an item from the scan list
  const handleDeleteItem = (item) => {
    console.log("Item Deleted: ", item);

    const deletedItem = scannedItems.filter((object) => object.id === item.id);

    // this pulls the deleted item out of scanned item list
    const newItems = scannedItems.filter((object) => object.id !== item.id);
    // then we renumber the list with the index
    const orderedItems = newItems.map((item, index) => {
      return { ...item, row: index + 1 };
    });

    setScannedItems(orderedItems);

    dispatch(
      alertActions.showAlert({
        title: "Deleted Item",
        message: `${deletedItem[0].size} ${deletedItem[0].family} deleted from list.`,
      })
    );

    setNumItems(numItems - 1);
  };

  // This function adds an item to the potential order items.  Whether it was scanned or manually added.
  const addItem = (item) => {
    const id = uuidv4();

    const newItem = {
      id: id,
      row: numItems + 1,
      item_id: item.id,
      ferg_pn:item.ferg_pn,
      mks_pn:item.mks_pn,
      part_no: vendorId==='ferg'?item.ferg_pn:item.mks_pn,
      size: item.size,
      family: item.description,
      type: "scanned",
      qty: item.is_pipe ? currentQty * 21 : Number(currentQty),
      is_pipe: item.is_pipe,
    };

    setScannedItems([...scannedItems, newItem]);

    setNumItems(numItems + 1);
    // this will move the list to the last page so the user can see the current item that was just scanned
    const newPage = Math.floor(numItems / 7);

    if (newPage !== page) {
      setPage(newPage);
    }

    setCurrentItem("");
    setCurrentDesc("");
    setCurrentQty(1);
    setItemSelected("");

    console.log("here are the scanned items: ",scannedItems);
    console.log("here is the vendor id: ", vendorId);
  };

  //This function looks for an enter key at the end of the scan and if it is pressed selects the item from our inventory
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      const itemSelected = inventory.find(
        (item) => (vendor_id==='ferg'?item.ferg_pn === currentItem.toUpperCase():item.mks_pn === currentItem.toUpperCase())
      );

      console.log(
        "Here is the item selected when it doesnt find it",
        itemSelected
      );

      //If it doesnt find the item in our inventory it alerts the user and asks them if they want to add it to our inventory
      if (itemSelected == null) {
        if(
          window.confirm(
            `Is this item a part from ${vendor_id==='ferg'?'Ferguson':'MKS'}?`
          )
        ){
          if (
            window.confirm(
              "This item does not exist in our inventory. Would you like to add it?"
            )
          ) {
            setShowAddInv(true);
          } else {
            setCurrentItem("");
          }
        } else {
          setCurrentItem("");
        }
      } else {
        addItem(itemSelected);
      }
    }
  };

  // This function adds an item that was added to our inventory manually it is sent to the AddItemModal and receives the item added through that modal
  const handleAddItem = (item) => {
    addItem(item);

    // I need to take the item and add it as well to our current inventory
    const newInventory = [...inventory, { ...item }];
    console.log("Here is the new inventory: ", newInventory);

    // the following code below is duplicated in our useEffect method
    setInventory([...newInventory]);

    const descr = newInventory.map((item) => {
      return item.description;
    });

    const uniqueDesc = descr.filter(
      (descr, index, array) => array.indexOf(descr) === index
    );

    const sortedDesc = uniqueDesc.sort((a, b) => (a > b ? 1 : -1));

    setDescriptions(sortedDesc);

    setShowAddInv(false);
  };

  // this cancels adding an item to our inventory. It is sent to the AddItemModal
  const handleCancelAddInv = () => {
    setShowAddInv(false);
    setCurrentItem("");
  };

  // This submits the scanned items to the main order component and closes the scanning modal.  It then sets the scanned items to blank.
  const handleSubmit = () => {
    handleAddItems(scannedItems);
    setShowModal(false);
    scannerCancel("scanner");
    setScannedItems([]);
    setNumItems(0);
  };

  //This closes the scanning modal without adding the items
  const handleClose = () => {
    setShowModal(false);
    scannerCancel("scanner");
    setScannedItems([]);
    setCurrentDesc("");
    setItemSelected("");
    setCurrentQty(1);
    setNumItems(0);
    setVendorId('');
  };

  // This handles the page changing since during scanning we are automatically moving it to the last page.
  const handleChangePage = (e) => {
    setPage(e);
  };

  return (
    <Box>
      <AlertDialog />
      <Dialog open={showModal} fullWidth maxWidth="md">
        <DialogTitle>Add Items to Order</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
            sx={{mt:1}}
              fullWidth    
              id="scan_items"
              //this label lets the user know if the field is selected and active so it can receive scans
              label={
                scanningLabel
                  ? "Scanning Activated"
                  : "Select To Start Scanning"
              }
              name="scan_items"
              autoComplete="off"
              autoFocus={true}
              type="text"
              value={currentItem}
              // this sets the active scanning status on whether the field is selected.
              onFocus={() => {
                setScanningLabel(true);
                setCurrentQty(1);
                setCurrentDesc("");
                setItemSelected("");
              }}
              onBlur={() => {
                setScanningLabel(false);
              }}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
            <Box
              sx={{ width: "100%", mt:1}}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="desc">Item</InputLabel>
                <Select
                  labelId="desc"
                  id="desc"
                  value={currentDesc}
                  name="desc"
                  label="desc"
                  onChange={handleChange}
                >
                  {descriptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 1, width: "10%" }}>
                <InputLabel id="size">Size</InputLabel>
                <Select
                  labelId="size"
                  id="size"
                  value={itemSelected}
                  name="size"
                  label="size"
                  onChange={handleChange}
                >
                  {itemOptions.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="qty"
                label="QTY"
                onChange={handleChange}
                variant="outlined"
                name="qty"
                type="number"
                value={currentQty}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: "1" }}
                sx={{ ml: { md: 1 }, width: "10%" }}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "15%", ml: 1 }}
                onClick={() => addItem(itemSelected)}
              >
                Stage Item
              </Button>
              <Button
                variant="contained"
                sx={{ width: "15%", ml:1 }}
                onClick={() => setShowAddInv(true)}
              >
                New Item
              </Button>
            </Box>
          </Box>
        </DialogContent>

        <div
          style={{
            height: 400,
            marginLeft:20,
            marginRight:20
          }}
        >
          <DataGrid
            getRowId={(row) => row.id}
            rows={scannedItems}
            columns={columns}
            autoPageSize
            disableDensitySelector={true}
            disableColumnSelector={true}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: 220 }}
            onClick={handleSubmit}
          >
            {`Add ${numItems} items to Order`}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ width: "25%" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AddItemModal
        open={showAddInv}
        sku={currentItem}
        vendor={vendorId}
        handleAddItem={handleAddItem}
        handleCancelAddItem={handleCancelAddInv}
      />
    </Box>
  );
};
