import { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import AlertDialog from "../utilities/AlertDialog";
import { ProjectModal } from "../admin/projects/ProjectModal";
import { addProject } from "../admin/projects/ProjectHelpers";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// this modal is for editing the project and cost code information for an Order/ It also is the same modal used to add another container to the order
export const OrderModal = ({
  order,
  open,
  handleCancel,
  handleEditCommit,
  handleAddContainer,
  handleAddOrder,
  modalType,
  modifyVendor
}) => {
  const [orderInfo, setOrderInfo] = useState({
    cost_code: "H4-40000",
    project: "",
    container: "",
    vendor_id: "ferg",
  });
  const [activeProjects, setActiveProjects] = useState([]);
  const [containers, setContainers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // this will open the add project dialog modal if we need to add a project
  const [addProjectOpen, setAddProjectOpen] = useState(false);
  const [buttonText, setButtonText] = useState("Create Order");
  const [editVendor, setEditVendor] = useState(true)

  const auth = useContext(AuthContext);

  const dispatch = useDispatch();

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    }),
    [auth.token]
  );

  // This first determines what the modal type is whether it is for adding a container or editing the order information
  // The useEffect gets the active projects only when the open modal is set to true. After getting the active projects from the database
  // it then selects the current project for the order and sets that in the modal it then shows the modal
  useEffect(() => {
    const getActiveProjects = async () => {
      try {
        if (modalType === "addContainer") {
          setButtonText("Add Container");
          const responseData = await axios.get(
            "/api/purchasing/containers",
            requestConfig
          );
          const serverContainers = responseData.data.containers;
          setContainers(serverContainers);
        } else {
          const responseData = await axios.get(
            "/api/projects/activeprojects",
            requestConfig
          );
          const serverActiveProjects = responseData.data.activeProjects;
          setActiveProjects(serverActiveProjects);

          // if it is an edit modal it sets the button name and then finds the current project in the list of active projects and sets it
          if (modalType === "edit") {
            setButtonText("Update Order");
            // I have to find the current project index in the list of active projects to set the modal
            const currentProjectIndex = serverActiveProjects.findIndex(
              (project) => {
                return project.id === order.project.id;
              }
            );

            if (currentProjectIndex < 0) {
              dispatch(
                alertActions.showAlert({
                  title: "Not An Active Project",
                  message:
                    "Your current project is not an active project in the system.  Please set the project to active or synchronize the projects from Stratus",
                })
              );
              return;
            }

            setOrderInfo({
              cost_code: order.cost_code,
              project: serverActiveProjects[currentProjectIndex],
              vendor_id:order.vendor_id
            });

            setEditVendor(modifyVendor);
          } else if (modalType === "createReturn") {
            setButtonText("Create Return");
          } else {
            setButtonText("Create Order");
          }
        }

        setShowModal(true);
      } catch (err) {
        console.log("Error receiving active projects: ", err);
        dispatch(
          alertActions.showAlert({
            title: "Error Receiving Active Projects",
            message:
              "There was an error receiving the active projects: " +
              err.response.data.message,
          })
        );
      }
    };
    if (open) {
      getActiveProjects();
    }
  }, [open, requestConfig, order, modalType]);

  // this updates the state when the value is changed in the modal
  const handleChange = (e) => {
    let itemChanged;

    if (modalType === "addContainer") {
      itemChanged = "container";
    } else {
      itemChanged = e.target.name;
    }

    const valueChanged = e.target.value;
    const changeOrderInfo = { ...orderInfo, [itemChanged]: valueChanged };

    setOrderInfo(changeOrderInfo);
    console.log(orderInfo);
  };

  // this is if the action is canceled and the modal is closed
  const handleClose = () => {
    setShowModal(false);
    setOrderInfo({
      cost_code: "H4-40000",
      project: "",
      container: "",
      vendor_id: "ferg",
    });
    handleCancel();
  };

  // this handles when the changes are submitted
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(false);

    if (modalType === "edit") {
      handleEditCommit(orderInfo);
    } else if (modalType === "addContainer") {
      handleAddContainer(orderInfo.container.id);
    } else {
      handleAddOrder(orderInfo);
    }
    setOrderInfo({
      cost_code: "H4-40000",
      project: "",
      container: "",
      vendor_id: "ferg",
    });
  };

  // this is if the user needs to add a project
  const handleAddProject = () => {
    setShowModal(false);
    setAddProjectOpen(true);
  };

  // this handles when a new project is added through the add project modal
  const submitNewProject = async (project) => {
    const id = uuidv4();
    const projectToAdd = { ...project, id: id };
    console.log("Here is the project to add: ", projectToAdd);

    const activeProjectsBefore = activeProjects;
    const orderInfoBefore = orderInfo;

    setActiveProjects([projectToAdd, ...activeProjects]);
    setOrderInfo({ ...orderInfo, project: projectToAdd });
    setShowModal(true);
    setAddProjectOpen(false);

    try {
      const addedProject = await addProject(projectToAdd, requestConfig);
      console.log("Here is the project that was added: ", addedProject);
    } catch (error) {
      console.log("There was an error adding the project: ", error);
      setActiveProjects(activeProjectsBefore);
      setOrderInfo(orderInfoBefore);
    }
  };

  // this is if the add project is canceled
  const handleCancelAddProject = async (project) => {
    setAddProjectOpen(false);
    setShowModal(true);
  };

  return (
    <div>
      <AlertDialog />
      <Dialog open={showModal}>
        <DialogTitle>
          {modalType === "addContainer"
            ? "Select Container to Add"
            : "Order Information"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth>
              <InputLabel id="project-label">
                {modalType === "addContainer" ? "Container" : "Project"}
              </InputLabel>
              <Select
                labelId="project-label"
                id="project"
                value={
                  modalType === "addContainer"
                    ? orderInfo.container
                    : orderInfo.project
                }
                name="project"
                label="Project"
                onChange={handleChange}
                required
              >
                {(modalType !== "addContainer"
                  ? activeProjects
                  : containers
                ).map((item) => (
                  <MenuItem key={item.id} value={item}>
                    {modalType !== "addContainer"
                      ? `${item.number} - ${item.name}`
                      : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              fullWidth
              margin="normal"
              id="cost_code"
              label="Cost Code"
              name="cost_code"
              autoComplete="off"
              type="text"
              value={orderInfo.cost_code}
              onChange={handleChange}
              sx={{
                visibility: modalType === "addContainer" ? "hidden" : "visible",
              }}
            />
            <FormControl sx={{ mt: 1, minWidth: 120, visibility: modalType === "addContainer" ? "hidden" : "visible" }} fullWidth>
              <InputLabel id="vendor-label">Vendor</InputLabel>
              <Select
                labelId="vendor-label"
                id="vendor"
                value={orderInfo.vendor_id}
                name="vendor_id"
                label="Vendor"
                onChange={handleChange}
                required
                disabled={!editVendor}
              >
                <MenuItem key="ferg" value="ferg">
                  Ferguson
                </MenuItem>
                <MenuItem key="mks" value="mks">
                  MKS
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" sx={{ width: 180 }}>
              {buttonText}
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: 150,
                visibility: modalType === "edit" ? "visible" : "hidden",
              }}
              onClick={handleAddProject}
            >
              Add Project
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "25%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ProjectModal
        editProject={false}
        open={addProjectOpen}
        handleCancel={handleCancelAddProject}
        handleAddProject={submitNewProject}
      />
    </div>
  );
};
