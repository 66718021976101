import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import { format, previousMonday } from "date-fns";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  gridSortedRowIdsSelector,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";



// this selects all unfiltered rows for exporting.
const getFilteredRows = ({ apiRef }) => {
  let exportedRows = gridSortedRowIdsSelector(apiRef);
  // exportedRows = exportedRows.filter(row=> !row.includes('Steele') & !row.includes('Ahlin') & !row.includes('Wessling')& !row.includes('Price, Jeremy'))
  exportedRows = exportedRows.filter(row=> !row.includes('Price, Jeremy'))
  console.log(exportedRows);
  return exportedRows;

};


export const TimecardDownload = () => {
  const auth = useContext(AuthContext);

  const [weekTimecards, setWeekTimecards] = useState([]);
  const [dailySummary, setDailySummary] = useState([]);
  const [packageSummary, setPackageSummary] = useState([]);
  const [weekEnd, setWeekEnd] = useState(null);
  const [summaryType, setSummaryType] = useState('timecard');
  const [dataRows, setDataRows] = useState([])


  // this selects only the sunday dates so they only appear in the calendar
  const isSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  const weekEndingDate =()=>{
    return weekEnd
  }

  useEffect(()=>{
    if(summaryType==="timecard"){
      setDataRows(weekTimecards)
    } else if (summaryType==="dailySummary"){
      setDataRows(dailySummary)
    }
    else if (summaryType==="packSummary") {
      setDataRows(packageSummary)
    }
  },[summaryType,dailySummary,weekTimecards,packageSummary])

  const columns = [
    {
      field:"weekEnding",
      headerName: "Week Ending",
      width: 95,
      valueGetter:weekEndingDate,
    },
    {
      field: "full_name",
      headerName: "Employee Name",
      width: 140,
      editable: false,
    },
    {
      field: "coins_empl",
      headerName: "COINS Employee #",
      width: 20,
      editable: false,
    },
    {
      field: "local_paid_union",
      headerName: "Local - Paid Union",
      width: 20,
      editable: false,
    },
    {
      field: "project_name",
      headerName: "Project",
      width: 300,
      editable: false,
    },
    {
      field: "project_number",
      headerName: "Proj #",
      width: 95,
      editable: false,
    },
    {
      field: "package_name",
      headerName: "Package",
      width: 200,
      editable: false,
    },
    {
      field: "activity_name",
      headerName: "Activity",
      width: 110,
      editable: false,
    },
    {
      field: "phase",
      headerName: "Ph",
      width: 10,
      editable: false,
    },
    {
      field: "cost_code",
      headerName: "CC",
      width: 70,
      editable: false,
    },
    {
      field: "straight_time",
      headerName: "STRAIGHT-TIME",
      width: 40,
      editable: false,
    },
    {
      field: "st",
      headerName: "TOTAL",
      width: 60,
      editable: false,
    },
    {
      field: "MON_ST",
      headerName: "MON",
      width: 40,
      editable: false,
    },
    {
      field: "TUE_ST",
      headerName: "TUE",
      width: 40,
      editable: false,
    },
    {
      field: "WED_ST",
      headerName: "WED",
      width: 40,
      editable: false,
    },
    {
      field: "THU_ST",
      headerName: "THU",
      width: 40,
      editable: false,
    },
    {
      field: "FRI_ST",
      headerName: "FRI",
      width: 40,
      editable: false,
    },
    {
      field: "SAT_ST",
      headerName: "SAT",
      width: 40,
      editable: false,
    },
    {
      field: "SUN_ST",
      headerName: "SUN",
      width: 40,
      editable: false,
    },
    {
      field: "overtime",
      headerName: "OVERTIME",
      width: 40,
      editable: false,
    },
    {
      field: "ot",
      headerName: "TOTAL",
      width: 60,
      editable: false,
    },
    {
      field: "MON_OT",
      headerName: "MON",
      width: 40,
      editable: false,
    },
    {
      field: "TUE_OT",
      headerName: "TUE",
      width: 40,
      editable: false,
    },
    {
      field: "WED_OT",
      headerName: "WED",
      width: 40,
      editable: false,
    },
    {
      field: "THU_OT",
      headerName: "THU",
      width: 40,
      editable: false,
    },
    {
      field: "FRI_OT",
      headerName: "FRI",
      width: 40,
      editable: false,
    },
    {
      field: "SAT_OT",
      headerName: "SAT",
      width: 40,
      editable: false,
    },
    {
      field: "SUN_OT",
      headerName: "SUN",
      width: 40,
      editable: false,
    },
    {
      field: "doubletime",
      headerName: "DOUBLETIME",
      width: 40,
      editable: false,
    },
    {
      field: "dt",
      headerName: "TOTAL",
      width: 60,
      editable: false,
    },
    {
      field: "MON_DT",
      headerName: "MON",
      width: 40,
      editable: false,
    },
    {
      field: "TUE_DT",
      headerName: "TUE",
      width: 40,
      editable: false,
    },
    {
      field: "WED_DT",
      headerName: "WED",
      width: 40,
      editable: false,
    },
    {
      field: "THU_DT",
      headerName: "THU",
      width: 40,
      editable: false,
    },
    {
      field: "FRI_DT",
      headerName: "FRI",
      width: 40,
      editable: false,
    },
    {
      field: "SAT_DT",
      headerName: "SAT",
      width: 40,
      editable: false,
    },
    {
      field: "SUN_DT",
      headerName: "SUN",
      width: 40,
      editable: false,
    },
    {
      field: "notes",
      headerName: "NOTES",
      width: 100,
      editable: false,
    },
  ];

  // this function sets the week ending date and gets the timecards for that week
  const getTimecardsWeek = async (newValue) => {
    const selectedWeek = format(newValue, "yyyy/MM/dd");

    setWeekEnd(selectedWeek);

    const weekStart = format(previousMonday(newValue), "yyyy/MM/dd");

    const requestData = {
      headers: {
        Authorization: "Bearer " + auth.token,
      },
      params: {
        date_beg: weekStart,
        date_end: selectedWeek,
      },
    };
    try {
      const responseData = await axios.get(
        "/api/timecards/gettimecardsweek",
        requestData
      );
      console.log(responseData.data);
      // console.log(responseData.data.time_week);
      setWeekTimecards(responseData.data.time_week);
      setDailySummary(responseData.data.time_summary);
      setPackageSummary(responseData.data.time_week_sum_pack)
      if(summaryType==="timecard"){
        setDataRows(responseData.data.time_week)
      } else if (summaryType==="dailySummary") {
        setDataRows(responseData.data.time_summary)
      } else if (summaryType==="packSummary") {
        setDataRows(responseData.data.time_week_sum_pack)
      }
    } catch (err) {
      console.log("there was an error getting the time for the week: ", err);
      alert(err.message);
    }
  };

  const columnGroupingModel = [
    {
      groupId: "Straight-Time",
      children: [
        { field: "st" },
        { field: "MON_ST" },
        { field: "TUE_ST" },
        { field: "WED_ST" },
        { field: "THU_ST" },
        { field: "FRI_ST" },
        { field: "SAT_ST" },
        { field: "SUN_ST" },
      ],
    },
    {
      groupId: "Overtime",
      children: [
        { field: "ot" },
        { field: "MON_OT" },
        { field: "TUE_OT" },
        { field: "WED_OT" },
        { field: "THU_OT" },
        { field: "FRI_OT" },
        { field: "SAT_OT" },
        { field: "SUN_OT" },
      ],
    },
    {
      groupId: "Doubletime",
      children: [
        { field: "dt" },
        { field: "MON_DT" },
        { field: "TUE_DT" },
        { field: "WED_DT" },
        { field: "THU_DT" },
        { field: "FRI_DT" },
        { field: "SAT_DT" },
        { field: "SUN_DT" },
      ],
    },
  ];

  return (
    <Box sx={{ width: "95%", height: "90%", padding: 2 }}>
      <Box sx={{ width: "100%", height: 60, paddingTop: 2 }}>
        <LocalizationProvider name="timeDate" dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Week Ending"
            inputFormat="yyyy/MM/dd"
            value={weekEnd}
            onChange={getTimecardsWeek}
            renderInput={(params) => <TextField {...params} />}
            shouldDisableDate={isSunday}
          />
        </LocalizationProvider>
        <FormControl sx={{ ml:2, minWidth: 120 }}>
        <InputLabel id="timecard_display">Display</InputLabel>
        <Select
          labelId="timecard_display"
          id="summary_type"
          value={summaryType}
          name="summary_type"
          label="Summary Type"
          onChange={(e)=>{setSummaryType(e.target.value)}}
          sx={{ height: 60, width:200 }}
        > 
            <MenuItem key="timecard" value="timecard">Timecard</MenuItem>
            <MenuItem key="dailySummary" value="dailySummary">Daily Summary</MenuItem>
            <MenuItem key="packSummary" value="packSummary">Package Summary</MenuItem>
        </Select>
      </FormControl>
      </Box>
      <Box sx={{ width: "100%", height: "100%", paddingTop: 2 }}>
        <DataGrid
          getRowId={(row) =>
            row.identifier
          }
          columns={columns}
          rows={dataRows}
          getRowHeight={() => 'auto'}
          components={{ Toolbar: GridToolbar }}
          // autoPageSize
          autoHeight
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          componentsProps={{
            toolbar: { csvOptions: { getRowsToExport: getFilteredRows, allColumns: true, fileName:`${weekEnd} Weekly Timecard Data` }},
          }}
          columnVisibilityModel={
            {
              weekEnding:false,
              coins_empl:false,
              local_paid_union:false,
              project_name:summaryType==='dailySummary'?false:true,
              project_number:summaryType==='dailySummary'?false:true,
              package_name:summaryType==='packSummary'?true:false,
              activity_name:summaryType==='packSummary'?true:false,
              phase:summaryType==='dailySummary'?false:true,
              cost_code:summaryType==='dailySummary'?false:true,
              straight_time:false,
              st:summaryType==='timecard'?false:true,
              overtime:false,
              ot:summaryType==='timecard'?false:true,
              doubletime:false,
              dt:summaryType==='timecard'?false:true,
            }
          }
          disableDensitySelector={true}
          disableColumnSelector={true}
        />
      </Box>
    </Box>
  );
};
