
import axios from "axios";

export const addProject = async (project, requestConfig) => {


  const data = { newProject: project };

  console.log("Received request to add project: ", data);

  try {
    const addedProject = await axios.post(
      "/api/projects/addproject",
      data,
      requestConfig
    );
    console.log(
      "Added the following project to the database: ",
      addedProject.data.addedProject
    );

    return addedProject;
  } catch (err) {
    console.log("Error adding project to the backend: ", err);

    const errorMessage = err.response.data.message;
    alert(errorMessage);
    throw new TypeError(errorMessage)
  }
};

export const addPackage = async (newPackage, requestConfig) => {


  const data = { package: newPackage };

  console.log("Received request to add package: ", data);

  try {
    const addedPackage = await axios.post(
      "/api/packages/addpackage",
      data,
      requestConfig
    );
    return addedPackage;
  } catch (err) {
    console.log("Error adding package to the backend: ", err);

    const errorMessage = err.response.data.message;
    alert(errorMessage);
    throw new TypeError(errorMessage)
  }
};

