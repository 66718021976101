import { useState, useEffect, useContext } from "react";
import { OrderModal } from "./OrderModal";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import AlertDialog from "../utilities/AlertDialog";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const OrderList = ({ type }) => {
  const [orders, setOrders] = useState([]);
  const [listType, setListType] = useState("");
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const auth = useContext(AuthContext);

  const dispatch = useDispatch();

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };

  const navigate = useNavigate();

  // This totals the parts that were scanned and the parts that were ordered from Stratus together to display the total all parts in the # of items columns
  const getTotalParts = (params) => {
    return (
      (params.row.modeled_part_qty ? Number(params.row.modeled_part_qty) : 0) +
      (params.row.scanned_part_qty ? Number(params.row.scanned_part_qty) : 0)
    );
  };

  const getVendor = (params)=> {
    if(params.row.vendor_id==='mks'){
      return 'MKS'
    } else if (params.row.vendor_id==='ferg') {
      return 'Ferguson'
    } else {
      return ''
    }
  }

  const columns = [
    {
      field: listType === "orders" ? "vmi_order_no" : "vmi_return_no",
      headerName: listType === "orders" ? "Order #" : "Return #",
      width: 140,
      renderCell: (params) => (
        <Link
          to={
            listType === "orders"
              ? "/inventory/vmi/orders/" + params.row.vmi_order_no
              : "/inventory/vmi/returns/" + params.row.vmi_return_no
          }
        >
          {listType === "orders"
            ? params.row.vmi_order_no
            : params.row.vmi_return_no}
        </Link>
      ),
    },
    {
      field: "project_no",
      headerName: "Project #",
      flex: 1,
    },
    {
      field: "project_name",
      headerName: "Project",
      flex: 4,
    },
    {
      field: "cost_code",
      headerName: "Cost Code",
      flex: 1,
    },
    {
      field: "vendor_id",
      headerName: "Vendor",
      flex: 1,
      valueGetter:getVendor,
    },
    {
      field: "total_parts",
      headerName: "# Items",
      flex: 1,
      valueGetter: getTotalParts,
    },
    {
      field: "date",
      headerName: "Date Created",
      flex: 2,
      valueFormatter: (params) =>
        moment(params?.value).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: listType === "orders" ? "po_status" : "ret_status",
      headerName: listType === "orders" ? "PO Status" : "Return Status",
      editable: true,
      type: "singleSelect",
      valueOptions: statusOptions,
      flex: 2,
    },
    {
      field: listType === "orders" ? "p1_po" : "credit_no",
      headerName: listType === "orders" ? "PO #" : "CM #",
      flex: 1,
      editable: true,
    },
  ];

  const handleChange = async (e) => {
    const oldOrderList = orders;

    try {
      const field = e.field;
      const value = e.value;
      let status;
      let docNumber;
      const docId = e.id;
      let newOrderList;
      let changeItem = true;

      console.log(
        `The field changed was ${field}. The id changed was ${docId}. And the value changed was ${value}`
      );

      // this checks if it was the status column that changed and then finds the item based on the docId
      if (field === "ret_status" || field === "po_status") {
        status = value;
        const document = orders.filter((order) =>
          field === "po_status"
            ? order.vmi_order_no === docId
            : order.vmi_return_no === docId
        )[0];
        console.log("Here is the document: ", document);

        docNumber = listType === "orders" ? document.p1_po : document.credit_no;
      } else {
        docNumber = value;
        status = listType === "orders" ? "PO Issued" : "Credit Memo Issued";

        changeItem = window.confirm(
          `Do you want to update the ${
            listType === "orders" ? "PO#" : "CM#"
          } for ${docId} to ${docNumber} and change the status to ${status}? `
        );
      }

      if (changeItem) {
        newOrderList = orders.map((order) => {
          const numList =
            listType === "orders" ? order.vmi_order_no : order.vmi_return_no;

          if (numList === docId) {
            if (listType === "orders") {
              if (field === "po_status") {
                return { ...order, po_status: status };
              } else {
                return { ...order, p1_po: value, po_status: status };
              }
            } else {
              if (field === "ret_status") {
                return { ...order, ret_status: status };
              } else {
                return { ...order, credit_no: value, ret_status: status };
              }
            }
          } else {
            return order;
          }
        });

        setOrders(newOrderList);

        const data = {
          order_no: docId,
          docNumber: docNumber,
          status: status,
          doctype: listType,
        };

        const updatePo = await axios.patch(
          "/api/purchasing/updatedocumentinfo",
          data,
          requestConfig
        );

        console.log(updatePo);
      } else {
        const sameItems = orders.map((order) => {
          return { ...order };
        });
        setOrders(sameItems);
      }
    } catch (err) {
      console.log("There was an error updating the order: ", err);
      setOrders(oldOrderList)
      dispatch(
        alertActions.showAlert({
          title: "Error updating the Order",
          message: "There was an error updating the order: " + err.data.message,
        })
      );
    }
  };

  const getOrders = async () => {
    let url = "/api/purchasing/";
    if (listType === "orders") {
      url = url + "orderlist";
    } else if (listType === "returns") {
      url = url + "returnlist";
    }
    const orders = (await axios.get(url, requestConfig)).data.orderList;
    setOrders(orders);
    console.log("here are the orders: ", orders);
    console.log("Received a total number of orders : ", orders.length);
  };

  useEffect(() => {
    if (listType) {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType]);
  
  useEffect(() => {
    if (auth.token) {
      setListType(type);
      if (type === "orders") {
        setStatusOptions([
          "Created",
          "Submitted",
          "PO Issued",
          "Deleted",
        ]);
      } else {
        setStatusOptions([
          "Created",
          "Submitted",
          "Credit Memo Issued",
          "Credit Received",
          "Deleted",
        ]);
      }
    }
  }, [auth, type]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddOrder = async (orderInfo) => {
    try {
      setOpen(false);
      console.log("Here is the information for the new order:", orderInfo);

      const data = {
        projectId: orderInfo.project.id,
        cost_code: orderInfo.cost_code,
        vendor_id: orderInfo.vendor_id,
        type: listType,
      };

      const url = "/api/purchasing/addorder";

      const response = await axios.post(url, data, requestConfig);

      const orderNo = response.data.orderNo;

      console.log(response);

      navigate(orderNo);
    } catch (err) {
      console.log("There was an error creating the order: ", err);
      dispatch(
        alertActions.showAlert({
          title: "Error Creating Order",
          message: "There was an error creating the order: " + err.data.message,
        })
      );
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Box
    className="OrderListContainer"
    >
      <AlertDialog />
      <Box
        sx={{
          width: "90%",
          padding: 1,
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" onClick={() => handleOpen()}>
          {listType === "orders" ? "Create Order" : "Create Return"}
        </Button>
      </Box>
      <div style={{ height: "80vh", width: "95%", paddingLeft: 20 }}>
        <DataGrid
          getRowId={(row) =>
            listType === "orders" ? row.vmi_order_no : row.vmi_return_no
          }
          columns={columns}
          rows={orders}
          components={{ Toolbar: GridToolbar }}
          onCellEditCommit={handleChange}
          autoPageSize
          disableColumnSelector={true}
        />
      </div>
      <OrderModal
        open={open}
        handleCancel={handleCancel}
        handleAddOrder={handleAddOrder}
        modalType={listType === "orders" ? "createOrder" : "createReturn"}
      />
    </Box>
  );
};
