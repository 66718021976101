import React, { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { AuthContext } from "./context/auth-context";

const theme = createTheme();

export default function SignIn() {
  const auth = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const environment = process.env.NODE_ENV;
    console.log("Here is the enviroment: ", environment);
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    console.log(`Logging in: ${email}`);

    try {
      const response = await axios.post("/api/users/login", {
        email: email,
        password: password,
      });
      const loginInfo = response.data;
      console.log("Login Info from server: ", loginInfo);
      var tokenExpires = new Date();
      tokenExpires.setHours(
        tokenExpires.getHours() + loginInfo.tokenExpiresHours
      );
      console.log("Token expires at :", tokenExpires);
      auth.login(
        loginInfo.userId,
        loginInfo.email,
        loginInfo.token,
        tokenExpires,
        loginInfo.firstName,
        loginInfo.lastName,
        loginInfo.isAdmin,
        loginInfo.reset_password
      );
    } catch (err) {
      console.log(err);
      alert("Incorrect username or password");
      // window.location.reload(false);
    }
    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
