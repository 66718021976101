import { useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AlertDialog from "../utilities/AlertDialog";

const TimecardAddItem = (props) => {
  const { activeProjects, activePackages, activityOptions, addTimeItem } =
    props;

  const [projectPackages, setProjectPackages] = useState([]);
  const [packageAct, setPackageAct] = useState([]);
  const [newTimecard, setNewTimecard] = useState({
    project: "",
    package: "",
    activity: "",
    hours_st: 0,
    hours_ot: 0,
    hours_dt: 0,
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const type = e.target.type;
    let value =
      type === "number"
        ? parseFloat(e.target.value).toFixed(1)
        : e.target.value;

    if (e.target.name === "project") {
      const projPackages = activePackages.filter((pckg) => {
        if (pckg.project_id === value.id) {
          return true;
        } else {
          return false;
        }
      });

      setProjectPackages(projPackages);
      setPackageAct([]);

      setNewTimecard({
        ...newTimecard,
        [e.target.name]: value,
        package: "",
        activity: "",
      });
    } else if (e.target.name === "package") {
      console.log("Package Changed to: ", value);

      const packageCatId = value.package_cat_id;
      const availableActivities = activityOptions.filter(
        (option) => option.package_cat_id === packageCatId
      );
      setPackageAct(availableActivities);

      setNewTimecard({
        ...newTimecard,
        [e.target.name]: value,
        activity: "",
      });
    } else {
      setNewTimecard({
        ...newTimecard,
        [e.target.name]: value,
      });
    }
  };

  const handleSubmit = () => {
    if (newTimecard.project) {
      if (newTimecard.package) {
        if (newTimecard.activity) {
          addTimeItem(newTimecard);
          setNewTimecard({
            ...newTimecard,
            hours_st: 0,
            hours_ot: 0,
            hours_dt: 0,
          });
        } else {
          console.log("inside alert show alert for activity");
          dispatch(
            alertActions.showAlert({
              title: "Add an Activity",
              message: "The activity cannot be blank.",
            })
          );
        }
      } else {
        dispatch(
          alertActions.showAlert({
            title: "Add a Package",
            message: "The package cannot be blank.",
          })
        );
      }
    } else {
      dispatch(
        alertActions.showAlert({
          title: "Add a Project",
          message: "The project cannot be blank.",
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: { xs: 500, md: "100%" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <AlertDialog />
      <FormControl
        sx={{
          mt: 1,
          width: { xs: "100%", md: "20%" },
          height: 55,
        }}
      >
        <InputLabel id="project-label">Project</InputLabel>
        <Select
          labelId="project-label"
          id="project"
          value={newTimecard.project}
          name="project"
          label="Project"
          onChange={handleChange}
        >
          {activeProjects.map((project) => (
            <MenuItem key={project.id} value={project}>
              {`${project.number} - ${project.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          mt: 1,
          ml: { md: 1 },
          width: { xs: "100%", sx: "100%", md: "20%" },
          height: 55,
        }}
      >
        <InputLabel id="package-label">Package</InputLabel>
        <Select
          labelId="package-label"
          id="package"
          value={newTimecard.package}
          name="package"
          label="Package"
          onChange={handleChange}
        >
          {projectPackages.map((pkg) => (
            <MenuItem key={pkg.package_id} value={pkg}>
              {pkg.package_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          mt: 1,
          ml: { md: 1 },
          width: { xs: "100%", sx: "100%", md: "20%" },
          height: 55,
        }}
      >
        <InputLabel id="activity-label">Activity</InputLabel>
        <Select
          labelId="activity-label"
          id="activity"
          value={newTimecard.activity}
          name="activity"
          label="Activity"
          onChange={handleChange}
        >
          {packageAct.map((activity) => (
            <MenuItem key={activity.activity_id} value={activity}>
              {activity.activity_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 1,
          mb: 1,
        }}
      >
        <TextField
          id="hours_st"
          label="ST"
          onChange={handleChange}
          variant="outlined"
          name="hours_st"
          type="number"
          value={newTimecard.hours_st}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: ".5" }}
          sx={{ ml: { md: 1 }, width: 75 }}
        />
        <TextField
          id="hours_ot"
          label="OT"
          onChange={handleChange}
          variant="outlined"
          name="hours_ot"
          type="number"
          value={newTimecard.hours_ot}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: ".5" }}
          sx={{ ml: 1, width: 75 }}
        />
        <TextField
          id="hours_dt"
          label="DT"
          onChange={handleChange}
          variant="outlined"
          name="hours_dt"
          type="number"
          value={newTimecard.hours_dt}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: ".5" }}
          sx={{ ml: 1, width: 75 }}
        />
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{
          ml: { xs: 0, md: 1 },
          height: "50%",
          mt: { xs: 0, md: 1.5 },
          mb: { xs: 0.5, md: 0 },
          width: { xs: "50%", md: "10%" },
        }}
      >
        Add
      </Button>
    </Box>
  );
};

export default TimecardAddItem;
