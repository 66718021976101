import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";

// This component is used to display individual timecard items on an xtra small screen
const TimecardItem = (props) => {
  const { timeItem, handleDelete, handleEdit } = props;

  return (
    <Card variant="outlined" sx={{ m: 0.5 }}>
      <CardContent sx={{ p: 1, "&:last-child": { pb: 0 } }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" noWrap>
          {timeItem.project}
        </Typography>
        <Typography variant="body1" component="div" sx={{ mt: 0 }} noWrap>
          {timeItem.package_name}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {timeItem.activity_name}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 0,
          ml: 1,
        }}
      >
        <Typography variant="subtitle2" align="left">
          ST: {timeItem.hours_st}{" "}
          {timeItem.hours_ot > 0 && "OT:" + timeItem.hours_ot}{" "}
          {timeItem.hours_dt > 0 && "DT:" + timeItem.hours_dt}{" "}
          {timeItem.notes && (
            
              <Tooltip title={timeItem.notes} placement="top-start" arrow>
                <StickyNote2OutlinedIcon fontSize="small" />
              </Tooltip>
           
          )}
          {/* could change sticky note size to inherit */}
        </Typography>
        <Box>
          <Tooltip title="Edit item" placement="bottom-start">
            <IconButton id="edit" onClick={() => handleEdit(timeItem)}>
              <EditIcon id="edit" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Item" placement="bottom-start">
            <IconButton id="delete" onClick={() => handleDelete(timeItem)}>
              <DeleteIcon id="delete" color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default TimecardItem;
