import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const AddItemModal = ({ open, sku, vendor, handleAddItem, handleCancelAddItem }) => {
  const [newItem, setNewItem] = useState({
    sku: "",
    size: "",
    description: "",
    is_pipe: false,
    ferg_pn: "",
    mks_pn: "",
    desc_full: "",
    vendor_id: "",
  });
  const [showAddModal, setShowAddModal] = useState(false);

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (open) {
      // this changes the sku given to uppper case and then sets the new item with the sku
      setNewItem({
        sku: sku.toUpperCase(),
        size: "",
        description: "",
        is_pipe: false,
        ferg_pn: "",
        mks_pn: "",
        desc_full: "",
        vendor_id: vendor,

      });
      setShowAddModal(true);
    }
  }, [open, sku, vendor]);

  // this sets the state of the current item as it is changed.
  const handleChange = (e) => {

    const itemChanged = e.target.name;
    var newValue;
    var changedItem;

    if (itemChanged === "is_pipe") {
      newValue = e.target.checked;
      changedItem = { ...newItem, [itemChanged]: newValue };
    } else {
      const valueChanged = e.target.value;
      newValue = valueChanged.toUpperCase();

      if (itemChanged === "description") {
        changedItem = {
          ...newItem,
          [itemChanged]: newValue,
          desc_full: newValue,
        };
      } else if (itemChanged === "sku") {
        if (newItem.vendor_id === "ferg") {
          changedItem = {
            ...newItem,
            [itemChanged]: newValue,
            ferg_pn: newValue,
          };
        } else {
          changedItem = {
            ...newItem,
            [itemChanged]: newValue,
            mks_pn: newValue,
          };
        }
      } else {
        changedItem = { ...newItem, [itemChanged]: newValue };
      }
    }

    setNewItem(changedItem);
  };

  // This submits the new item to our database in the backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      const requestConfig = {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        };
      
        const data = {
          newItem: newItem,
        };
  
        const response = await axios.post(
          "/api/purchasing/addvmiinventory",
          data,
          requestConfig
        );

      const addedItem = response.data.addedItem;
  
      console.log("Here is the added item: ", addedItem);

      // This will add the added item to the current list of scanned items and then closes the modal
      handleAddItem(addedItem)
      setShowAddModal(false);
      setNewItem({
        sku: "",
        size: "",
        description: "",
        is_pipe: false,
        ferg_pn: "",
        mks_pn: "",
        desc_full: "",
        vendor_id: "",
      });

    } catch(err){
      console.log("There was an error adding the new item to the database: " + err.message);
    }
  };

  // this closes the modal without adding the item
  const handleClose = () => {
    setShowAddModal(false);
    handleCancelAddItem();
    setNewItem({
      sku: "",
      size: "",
      description: "",
      is_pipe: false,
      ferg_pn: "",
      mks_pn: "",
      desc_full: "",
      vendor_id: "",
    });
  };

  return (
    <Box>
      <Dialog open={showAddModal}>
        <DialogTitle>Add New VMI Inventory Item</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              required
              fullWidth
              margin="normal"
              id="sku"
              label="SKU"
              name="sku"
              autoComplete="off"
              type="text"
              value={newItem.sku}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              id="size"
              label="Size"
              name="size"
              autoComplete="off"
              type="text"
              value={newItem.size}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              id="description"
              label="Description"
              name="description"
              autoComplete="off"
              type="text"
              value={newItem.description}
              onChange={handleChange}
            />
            <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={newItem.is_pipe}
                  />
                }
                label="Check if item is pipe"
                labelPlacement="end"
                id="is_pipe"
                name="is_pipe"
              />
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" sx={{ width: 180 }}>
              Add Item
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "25%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};
