 export const combineParts =(parts,vendor)=>{
    let unique = [];
    let distinct = [];

    

    // this function takes all the items being ordered and combines them into quantities of each like item
    for (let i = 0; i < parts.length; i++) {

        let qrCodeUrl;

        if(parts[i].qr_code_url){
            qrCodeUrl = parts[i].qr_code_url.replace('/go', 'http://gtp.one');
        }

        let vendorSku = (vendor === "ferg" ? parts[i].ferg_pn : parts[i].mks_pn);

        // This creates a unique identifier based on a combination of the sku, family and size to check for
        let identifier = vendorSku + parts[i].family + parts[i].size + parts[i].type;

        let length = parts[i].length>0?parts[i].length:0;

        if (!unique[identifier]) {

            // let newObject = { ...parts[i], qty: 1, qr_code_url: qrCodeUrl, identifier: identifier, ids:[parts[i].id], length:length }
            let newObject = { ...parts[i], qr_code_url: qrCodeUrl, identifier: identifier, ids:[parts[i].id], length:length }
            distinct.push(newObject);
            unique[identifier] = 1;
        } else {
            let objIndex = distinct.findIndex((part => part.identifier === identifier));
            distinct[objIndex].qty = distinct[objIndex].qty + parts[i].qty;
            distinct[objIndex].qr_code_url = distinct[objIndex].qr_code_url + ' ' + qrCodeUrl;
            distinct[objIndex].ids = [...distinct[objIndex].ids, parts[i].id]
            distinct[objIndex].length = distinct[objIndex].length + length;
        }
    }

    return distinct;
}