import { useState, useEffect, useContext, useMemo } from "react";
import { PackageModal } from "./PackageModal";
import { addPackage } from "../projects/ProjectHelpers";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import { AuthContext } from "../../context/auth-context";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const Packages = () => {
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState("");
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState("");
  const [open, setOpen] = useState(false);
  const [editPackage, setEditPackage] = useState(false);

  const auth = useContext(AuthContext);

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    }),
    [auth.token]
  );

  // const requestConfig = {
  //   headers: {
  //     Authorization: "Bearer " + auth.token,
  //   },
  // };

  useEffect(() => {
    try {
      axios.get("/api/projects/activeprojects", requestConfig).then((res) => {
        if (res.data) {
          const projects = res.data.activeProjects;
          console.log("The projects received are: ", projects);
          setProjects(projects);
        } else {
          console.log(res);
        }
      });
    } catch (err) {
      console.log(
        "There was an error getting the active projects from the server: ",
        err
      );
      alert(err.message);
    }
  }, [requestConfig]);

  const handleProjectChange = async (e) => {
    const project = e.target.value;
    console.log("here is the selected project: ", project);
    setCurrentProject(project);
    try {
      const requestData = {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
        params: {
          projectId: project.id,
        },
      };
      const response = await axios.get("/api/packages/project", requestData);
      const projectPackages = response.data.packages;
      console.log("Here are the packages for this project: ", projectPackages);
      setPackages(projectPackages);
    } catch (err) {
      console.log("There was an error getting the packages for: ", project);
      const errorMessage = err.response.data.message;
      console.log("Here is the error: ", errorMessage);
      alert(errorMessage);
    }
  };

  const handleOpen = () => {
    setEditPackage(false);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setCurrentPackage("");
  };

  const handleAddPackage = async (addedPackage) => {
    const id = uuidv4();
    const newPackage = {
      id: id,
      name: addedPackage.name,
      in_stratus: addedPackage.in_stratus,
      category_id: addedPackage.package_cat.id,
      package_cat: addedPackage.package_cat.name,
      archived: addedPackage.archived,
      tracking_status: addedPackage.tracking_status.name,
      current_tracking_status_id: addedPackage.tracking_status.id,
      project_id: currentProject.id,
      cost_code: addedPackage.cost_code,
      phase: addedPackage.phase,
      units: addedPackage.units,
      uom:addedPackage.uom
    };
    console.log("Here is the package to be added: ", newPackage);

    const newPackageList = [...packages, newPackage];
    const sortedPackageList = newPackageList.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0
    );
    setPackages(sortedPackageList);

    setCurrentPackage("");

    setOpen(false);

    try {
      const addedPackage = await addPackage(newPackage, requestConfig);
      console.log(
        "Here is the package that was added: ",
        addedPackage.data.addedPackage
      );
    } catch (err) {
      console.log("There was an error adding the package: ", err);
      alert(err.response.data.message);
      setPackages(packages.filter((item) => item.id !== newPackage.id));
    }
  };

  const handleDeletePackage = async (rowSelected) => {
    const packageId = rowSelected.row.id;
    console.log("Here is the deletedPackageId: ", packageId);

    if (
      window.confirm(
        "Do you want to delete this package? " + rowSelected.row.name
      )
    ) {
      const oldPackages = packages;
      setPackages(packages.filter((item) => item.id !== packageId));

      const requestData = {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
        params: {
          packageId: packageId,
        },
      };

      try {
        const response = await axios.delete(
          "/api/packages/deletepackage",
          requestData
        );
        console.log("Deleted the following package: ", response);
      } catch (err) {
        console.log("Error deleteing the package in the backend: ", err);
        alert(err.response.data.message);
        setPackages(oldPackages);
      }
    }
  };

  const handleEditPackage = (rowSelected) => {
    const item = rowSelected.row;
    setCurrentPackage({
      id: item.id,
      name: item.name,
      in_stratus: item.in_stratus,
      category_id: item.category_id,
      package_cat: item.package_cat,
      archived: item.archived,
      tracking_status: item.tracking_status,
      current_tracking_status_id: item.current_tracking_status_id,
      project_id: currentProject.id,
      cost_code: item.cost_code,
      phase: item.phase,
      units: item.units,
      uom:item.uom
    });
    console.log("Here is the package being edited: ", item);
    setEditPackage(true);
    setOpen(true);
  };

  const handleUpdatePackage = async (editedPackage) => {
    console.log("Here is the updated package info: ", editedPackage);

    const updatedPackage = {
      id: editedPackage.id,
      name: editedPackage.name,
      in_stratus: editedPackage.in_stratus,
      category_id: editedPackage.package_cat.id,
      package_cat: editedPackage.package_cat.name,
      archived: editedPackage.archived,
      tracking_status: editedPackage.tracking_status.name,
      current_tracking_status_id: editedPackage.tracking_status.id,
      project_id: currentProject.id,
      cost_code: editedPackage.cost_code,
      phase: editedPackage.phase,
      units:editedPackage.units,
      uom:editedPackage.uom
    };

    setOpen(false);

    const previousPackages = packages;

    const updatedPackages = packages.map((item) => {
      if (item.id === updatedPackage.id) {
        return updatedPackage;
      } else {
        return item;
      }
    });

    setPackages(updatedPackages);

    const data = { updatedPackage: updatedPackage };

    try {
      const modifiedPackage = await axios.put(
        "/api/packages/modifypackage",
        data,
        requestConfig
      );
      console.log(
        "Modified the following package in the database: ",
        modifiedPackage.data.modifiedPackage
      );
      const numTimecardsUpdated = modifiedPackage.data.numTimecards;

      if(numTimecardsUpdated>0){
        alert(`There were ${numTimecardsUpdated} timecard entries updated with the new phase and cost code`)
      }

    } catch (err) {
      console.log("there was an error updating the package: ", err);
      alert(err.response.data.message);
      setPackages(previousPackages);
    }
  };

  const columns = [
    { field: "name", headerName: "Package", width: 300, editable: false },
    {
      field: "package_cat",
      headerName: "Category",
      width: 260,
    },
    {
      field: "phase",
      headerName: "Phase",
      width: 130,
    },
    {
      field: "cost_code",
      headerName: "Cost Code",
      width: 130,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 130,
    },
    {
      field: "units",
      headerName: "Units",
      width: 130,
    },
    {
      field: "uom",
      headerName: "UOM",
      width: 130,
    },
    {
      field: "tracking_status",
      headerName: "Tracking Status",
      width: 260,
    },
    {
      field: "in_stratus",
      headerName: "Stratus Package",
      width: 130,
      type: "boolean",
    },
    {
      field: "archived",
      headerName: "Archived",
      width: 130,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      headerName: "",
      cellClassName: "actions",
      getActions: (id) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          name="edit"
          label="Edit"
          onClick={() => {
            handleEditPackage(id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          name="delete"
          label="Delete"
          onClick={() => {
            handleDeletePackage(id);
          }}
        />,
      ],
    },
  ];

  return (
    <div>
      <Box
        sx={{
          width: "75%",
          padding: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project"
            value={currentProject}
            name="project"
            label="Project"
            onChange={handleProjectChange}
            sx={{ height: 50 }}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project}>
                {`${project.number} - ${project.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          sx={{ m: 1, height: 50 }}
          variant="contained"
          disabled={currentProject ? false : true}
          onClick={() => handleOpen()}
        >
          Add Package
        </Button>
      </Box>

      <Box
        sx={{
          width: "98%",
          height: "75vh",
          // height: 771 9/5/24 this was set at a fixed height and then I increased the nav bar
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <PackageModal
          packageSelected={currentPackage}
          editPackage={editPackage}
          open={open}
          handleCancel={handleCancel}
          handleAddPackage={handleAddPackage}
          handleUpdatePackage={handleUpdatePackage}
        />

        <DataGrid
          getRowId={(row) => row.id}
          columns={columns}
          rows={packages}
          components={{ Toolbar: GridToolbar }}
          autoPageSize
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
          }}
          disableDensitySelector={true}
          disableColumnSelector={true}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "archived",
                    operatorValue: "is",
                    value: "false",
                  },
                ],
              },
            },
          }}
        />
      </Box>
    </div>
  );
};
