import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const PackageModal = ({
  packageSelected,
  editPackage,
  open,
  handleCancel,
  handleAddPackage,
  handleUpdatePackage,
}) => {

  const [currentPackage, setCurrentPackage] = useState({
    id: "",
    name: "",
    package_cat:"",
    tracking_status: "",
    archived: false,
    in_stratus: false,
    phase:"",
    cost_code:"",
    units:0,
    uom:""
  });

  const [packageCategories, setPackageCategories] = useState([]);
  const [trackingStatuses, setTrackingStatuses] = useState([]);

  
  const auth = useContext(AuthContext);
  
  useEffect(()=>{

    const requestConfig = {
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    };

    const getPackageOptions = async ()=>{
      try{
        const response = await axios.get("/api/packages/packageoptions",requestConfig);
        const trackingList = response.data.trackingStatuses;
        const categoryList = response.data.packageCategories
        setPackageCategories(categoryList);
        setTrackingStatuses(trackingList);

        if(editPackage){
          const currentPackageCatId= packageSelected.category_id;
          const currentTrackingStatusId= packageSelected.current_tracking_status_id
          console.log("Here is the current package category id: ",currentPackageCatId);
          console.log("Here is the category list: ", categoryList);
          const trackingStatusIndex = trackingList.findIndex((item)=>{return item.id === currentTrackingStatusId});
          const categoryIndex = categoryList.findIndex((item)=>{return item.id === currentPackageCatId})
          console.log("Here is the current tracking index: ", trackingStatusIndex);

          console.log("here is the package selected: ", packageSelected);

          setCurrentPackage({
            id:packageSelected.id,
            name:packageSelected.name,
            package_cat:categoryList[categoryIndex],
            cost_code:packageSelected.cost_code,
            phase:packageSelected.phase,
            units:packageSelected.units,
            uom:packageSelected.uom,
            tracking_status:trackingList[trackingStatusIndex],
            archived:packageSelected.archived,
            in_stratus:packageSelected.in_stratus
          });
        }

      } catch(err){
        console.log(err);
        const errorMessage = err.response.data.message; 
        console.log("Here is the error: ", errorMessage);
        alert(errorMessage);
      }
    }
    if(open){
      getPackageOptions()
    }
  },[open, editPackage, packageSelected, auth.token])

  const handleChange = (e) => {
    const itemChanged = e.target.name;

    let valueChanged;

    if (itemChanged === "in_stratus" || itemChanged === "archived") {
      valueChanged = e.target.checked;
    } else {
      valueChanged = e.target.value;
    }
    
    let changePackage = { ...currentPackage, [itemChanged]: valueChanged };

    

    if(itemChanged ==="package_cat"){
      
      const phaseCostCode = currentPackage.phase + currentPackage.cost_code;

      if(phaseCostCode!==""){
        if(window.confirm("Do you want to update the phase and cost code wtih the package category phase and cost code?")){

          changePackage ={
            ...changePackage,
            phase:valueChanged.phase,
            cost_code:valueChanged.costcode
          } 
        }
      }else{
        changePackage ={
          ...changePackage,
          phase:valueChanged.phase,
          cost_code:valueChanged.costcode
        } 
      }


    }
    setCurrentPackage(changePackage);
  };

  const handleClose = () => {
    setCurrentPackage({
      id: "",
      name: "",
      package_cat: "",
      tracking_status: "",
      archived: false,
      in_stratus: false,
      cost_code:"",
      phase:"",
      units:0,
      uom:""
    });

    handleCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Here is the current package: ", currentPackage);

    editPackage
      ? handleUpdatePackage(currentPackage)
      : handleAddPackage(currentPackage);

    setCurrentPackage({
      id: "",
      name: "",
      package_cat: "",
      tracking_status: "",
      archived: false,
      in_stratus: false,
      cost_code:"",
      phase:"",
      units:0,
      uom:""
    });
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          {editPackage ? "Edit Package" : "New Package"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            required
            autoFocus
            fullWidth
            margin="normal"
            id="name"
            label="Package Name"
            name="name"
            autoComplete="off"
            type="text"
            value={currentPackage.name}
            onChange={handleChange}
          />
          <FormControl sx={{ mb:1,  minWidth: 550 }}>
            <InputLabel id="package_cat">Package Category</InputLabel>
            <Select
              required
              labelId="package_cat"
              id="package_cat"
              value={currentPackage.package_cat}
              name="package_cat"
              label="package_cat"
              onChange={handleChange}
              
            >
              {packageCategories.map((category) => (
                <MenuItem key={category.id} value={category}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              color: "secondary",
            }}
          >
          <TextField
            required
            margin="normal"
            id="phase"
            label="Phase"
            name="phase"
            autoComplete="off"
            type="text"
            value={currentPackage.phase}
            onChange={handleChange}
          />
          <TextField
            required
            margin="normal"
            id="cost_code"
            label="Cost Code"
            name="cost_code"
            autoComplete="off"
            type="text"
            value={currentPackage.cost_code}
            onChange={handleChange}
            sx={{
              ml:2
            }}
          />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              color: "secondary",
            }}
          >
          <TextField
            margin="normal"
            id="units"
            label="Units"
            name="units"
            autoComplete="off"
            type="text"
            value={currentPackage.units}
            onChange={handleChange}
          />
          <FormControl             
          sx={{
              m:2,
              minWidth: 100
            }}>
            <InputLabel id="uom">UOM</InputLabel>
            <Select
              labelId="uom"
              id="uom"
              value={currentPackage.uom}
              name="uom"
              label="uom"
              onChange={handleChange}   
            >
                <MenuItem key='ea' value='ea'>ea</MenuItem>
                <MenuItem key='ft' value='ft'>ft</MenuItem>   
                <MenuItem key='in' value='in'>in</MenuItem>   
            </Select>
          </FormControl>
          </Box>
          <FormControl sx={{ minWidth: 550 }}>
            <InputLabel id="tracking_status">Tracking Status</InputLabel>
            <Select
              required
              labelId="tracking_status"
              id="tracking_status"
              value={currentPackage.tracking_status}
              name="tracking_status"
              label="tracking_status"
              onChange={handleChange}
              
            >
              {trackingStatuses.map((status) => (
                <MenuItem key={status.id} value={status}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              color: "secondary",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={currentPackage.archived}
                />
              }
              label="Archived"
              labelPlacement="start"
              id="archived"
              name="archived"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={currentPackage.in_stratus}
                />
              }
              label="In Stratus"
              labelPlacement="start"
              id="in_stratus"
              name="in_stratus"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: 180 }}
            // onClick={handleSubmit}
          >
            {editPackage ? "Update Package" : "Add Package"}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ width: "25%" }}
          >
            Cancel
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
