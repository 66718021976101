import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";

export const AddUsers = ({
  user,
  editUser,
  open,
  handleCancel,
  handleAddUser,
  handleUpdateUser,
}) => {
  const [newUser, setNewUser] = useState(user);

  useEffect(() => {
    if (user) {
      setNewUser(user);
    }
  }, [user]);

  const localUnions = ["P008", "P4411A", "P4412A", "P4412B", "L1290"];

  const handleChange = (e) => {
    e.preventDefault();
    const itemChanged = e.target.name;
    const valueChanged =
      itemChanged === "shop_timecard" ||
      itemChanged === "is_admin" ||
      itemChanged === "metal_trade"
        ? e.target.checked
        : e.target.value;
    const changeUser = { ...newUser, [itemChanged]: valueChanged };
    setNewUser(changeUser);
  };

  const handleClose = () => {
    setNewUser({
      firstname: "",
      lastname: "",
      email: "",
      coins_empl: "",
      shop_timecard: true,
      local_paid_union: "",
      is_admin: false,
      metal_trade: false,
    });
    handleCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    editUser ? handleUpdateUser(newUser) : handleAddUser(newUser);

    setNewUser({
      firstname: "",
      lastname: "",
      email: "",
      coins_empl: "",
      shop_timecard: true,
      local_paid_union: "",
      is_admin: false,
      metal_trade: false,
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{editUser ? "Edit User" : "New User"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            autoFocus
            fullWidth
            margin="normal"
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="off"
            type="text"
            value={newUser.firstname}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="off"
            type="text"
            value={newUser.lastname}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="email"
            label="Email or Login Username"
            name="email"
            autoComplete="off"
            type="text"
            value={newUser.email}
            onChange={handleChange}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              color: "text.secondary",
            }}
          >
            <TextField
              margin="normal"
              width="40%"
              id="coins_empl"
              label="COINS Id"
              name="coins_empl"
              autoComplete="off"
              type="text"
              value={newUser.coins_empl}
              onChange={handleChange}
            />
            <FormControl
            sx={{width:"40%"}}   
            // fullWidth
            >
              <Autocomplete
                id="local_paid_union"
                name="local_paid_union"
                onChange={(event, value) => {
                  setNewUser({ ...newUser, local_paid_union: value });
                }}
                value={newUser.local_paid_union}
                inputValue={newUser.local_paid_union}
                onInputChange={(event, newInputValue) => {
                  setNewUser({ ...newUser, local_paid_union: newInputValue });
                }}
                freeSolo 
                options={localUnions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="local_paid_union"
                    margin="normal"
                    width="80px"
                    label="Local Union"
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              color: "text.secondary",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox onChange={handleChange} checked={newUser.is_admin} />
              }
              label="Administrator"
              labelPlacement="bottom"
              id="is_admin"
              name="is_admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={newUser.shop_timecard}
                />
              }
              label="Shop Timecard"
              labelPlacement="bottom"
              id="shop_timecard"
              name="shop_timecard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={newUser.metal_trade}
                />
              }
              label="Metal Tradesman"
              labelPlacement="bottom"
              id="metal_trade"
              name="metal_trade"
            />
          </Box>
          <DialogActions>
            <Button type="submit" variant="contained" sx={{ width: "25%" }}>
              {editUser ? "Update User" : "Add User"}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "25%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
