import { useState, useEffect, useContext, useMemo } from "react";
import { AddUsers } from "./AddUser";
import { AuthContext } from "../../context/auth-context";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';



export const Users = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser]=useState({
    id:"",
    firstname: "",
    lastname: "",
    email: "",
    coins_empl: "",
    shop_timecard: true,
    local_paid_union: "",
    is_admin:false,
    metal_trade:false
  });
  const [editUser,setEditUser]=useState(false)

  const auth = useContext(AuthContext);

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    }),
    [auth.token]
  );

  useEffect(() => {
    const getUsers = async () => {
      try {
        const result = await axios.get("/api/users/getusers", requestConfig);
        console.log(result.data.users);
        setUsers(result.data.users);
        return;
      } catch (err) {
        alert("There was an error getting the users");
        console.log("Error getting the users :", err);
      }
    };
    getUsers();
  }, [requestConfig]);

  const handleOpen = () => {
    setCurrentUser({
      id:"",
      firstname: "",
      lastname: "",
      email: "",
      coins_empl: "",
      shop_timecard: true,
      local_paid_union: "",
      is_admin:false,
      metal_trade:false
    })
    setEditUser(false)
    setOpen(true);
  };

  const handleAddUser = async (user) => {
    const id = uuidv4();
    const newUser = { ...user, id: id };
    console.log("Here is the added user: ", newUser);

    const newUserList = [...users, newUser];
    const sortedUserList = newUserList.sort((a, b) =>
      a.firstname.toLowerCase() + a.lastname.toLowerCase() >
      b.firstname.toLowerCase() + b.lastname.toLowerCase()
        ? 1
        : a.firstname.toLowerCase() + a.lastname.toLowerCase() <
          b.firstname.toLowerCase() + b.lastname.toLowerCase()
        ? -1
        : 0
    );
    setUsers(sortedUserList);
    setEditUser(false)
    setOpen(false);
    const data = { newUser: newUser };

    try {
      const addedUser = await axios.post(
        "/api/users/adduser",
        data,
        requestConfig
      );
      console.log("Added the following user to the database: ", addedUser);
    } catch (err) {
      console.log("Error adding user to the backend: ", err);
      alert("There was an error adding the user");
      setUsers(users.filter((user) => user.id !== id));
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDeleteUser =async (id)=>{
    if(window.confirm("Do you want to delete this user?")){

      const oldUsers= users;
      setUsers(users.filter((user) => user.id !== id));

      const requestData = {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
        params: {
          deletedUserId: id,
        },
      };
      

      try {
        console.log("Here is the auth token: ", requestData);
        
        const response = await axios.delete("/api/users/deleteuser", requestData);
        console.log("Deleted the following user: ", response);

      } catch (err){
        console.log("Error deleteing the user in the backend: ", err);
        alert(err.response.data.message);
        setUsers(oldUsers);
      }

    }
  }

  const handleUpdateUser= async (updatedUser)=>{
    console.log("Here is the updated user info: ", updatedUser);
    setOpen(false);
    const previousUsers= users;
    const updatedUsers= users.map((user)=>{
      if(user.id===updatedUser.id){
        return({
          ...updatedUser
        })
      } else{
        return user;
      }
    });

    const data ={ updatedUser: updatedUser }

    
    try{
      const modifiedUser = await axios.put(
        "/api/users/modifyuser",
        data,
        requestConfig
      );
      console.log("Modified the following user in the database: ", modifiedUser.data.modifiedUser);
    } catch (err){
      console.log("there was an error updating the user: ", err);
      alert("We were unable to update the user");
      setUsers(previousUsers);
    }


    setUsers(updatedUsers)
    
  }

  const handleEditUser =(id)=>{

    const user= id.row;
    setCurrentUser({
      id:user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      coins_empl:user.coins_empl===null?"":user.coins_empl,
      shop_timecard: user.shop_timecard===null?false:user.shop_timecard,
      local_paid_union: user.local_paid_union===null?"":user.local_paid_union,
      is_admin: user.is_admin===null?false:user.is_admin,
      metal_trade: user.metal_trade===null?false:user.metal_trade
    });
    setEditUser(true);
    setOpen(true);
    // alert("Edit User")
    console.log("Edit User: ", id.row);
  }

  const columns = [
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      editable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 400,
      editable: false,
    },
    {
      field: "is_admin",
      headerName: "Admin",
      width: 150,
      editable: false,
      type:'boolean'
    },
    {
      field: "shop_timecard",
      headerName: "Shop Timecard",
      width: 150,
      editable: false,
      type:'boolean'
    },
    {
      field: "metal_trade",
      headerName: "Mtl Trd",
      width: 100,
      editable: false,
      type:'boolean'
    },
    {
      field: "coins_empl",
      headerName: "COINS ID",
      width: 100,
      editable: false,
    },
    {
      field: "local_paid_union",
      headerName: "Local Paid Union",
      width: 200,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      headerName: "",
      cellClassName: "actions",
      getActions: (id) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          name="edit"
          label="Edit"
          onClick={() => {
            handleEditUser(id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="action" />}
          name="delete"
          label="Delete"
          onClick={() => {
            handleDeleteUser(id.id);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <Box sx={{ 
      width: "100%", 
      height: "75vh",
      // height: 771 9/5/24 this was set at a fixed height and then I increased the nav bar
      paddingTop: 2 }}>
      <Button variant="contained" onClick={() => handleOpen()}>
        Add User
      </Button>
      <AddUsers
        user={currentUser}
        editUser={editUser}
        open={open}
        handleCancel={handleCancel}
        handleAddUser={handleAddUser}
        handleUpdateUser={handleUpdateUser}
      />
      <Box sx={{ 
        width: "98%", 
        height: "75vh",
        // height: 771 9/5/24 this was set at a fixed height and then I increased the nav bar
        paddingTop: 2 }}>
        <DataGrid
          getRowId={(row) => row.id}
          columns={columns}
          rows={users}
          // rowHeight={20}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar:{
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            }
          }}
          initialState={{
            filter:{
              filterModel:{
                items:[{columnField :'shop_timecard', operatorValue:'is', value:'true'}]
              }
            }
          }}
          disableDensitySelector={true}
          disableColumnSelector={true}
          
        />
      </Box>
    </Box>
  );
};
